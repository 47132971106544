<template>
  <div v-if="blnShowComponent == true" class="form-check">
    <!-- <div class="form-check"> -->
    <input
      class="form-check-input"
      v-if="blncanChang == false"
      type="radio"
      :checked="blncheck"
      value="0"
      :name="inputname"
      :id="name"
      ref="radio"
    />
    <input
      class="form-check-input pe-none"
      v-if="blncanChang == true"
      type="radio"
      disabled
      :checked="blncheck"
      value="0"
      ref="radio"
    />
    <label class="form-check-label" :for="name">
      <span v-if="labelname == undefined || labelname == ''">{{ name }}</span>
      <span v-if="labelname != undefined || labelname != ''">
        {{ labelname }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    name: { type: String },
    labelname: { type: String },
    inputname: { type: String },
    value: { type: String },
    modelValue: String,
    canChange: { type: Boolean, default: false },
    usechecked: { type: Boolean },
  },
  computed: {
    blncheck() {
      return this.check;
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        // console.log(newValue);
        this.check = this.usechecked || this.value;
      },
    },
    canChange: {
      deep: true,
      handler() {
        // console.log(newValue);
        this.blncanChang = this.canChange;
      },
    },
  },
  beforeMount() {},
  mounted() {
    setTimeout(() => {
      this.check = this.usechecked || this.value;
      this.blnShowComponent = true;
      this.blncanChang = this.canChange;
      // setTimeout(() => {
        // userinfo disable
        // console.log(this.value)
        // this.$emit("dataValue", this.value);
      // }, 100);
    }, 500);
  },
  data() {
    return {
      check: { Type: Boolean, default: false },
      blnShowComponent: { Type: Boolean, default: false },
      blncanChang: false,
    };
  },
  methods: {
    updateValue(event) {
      this.check = event;
    },
  },
};
</script>

<style></style>
